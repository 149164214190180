import React from 'react';
import Image from 'reusecore/src/elements/Image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import DocumentationSection from '../../containers/SaasModern/Documentation';
import '../../containers/SaasModern/Documentation/documentation.style';
import two_years_side_by_side from '../../../../common/src/assets/image/2020/2_years_side_by_side.png';
import template_list_multiple_years from '../../../../common/src/assets/image/2020/template_list_multiple_years.png';
import template_list_week_with_tasks from '../../../../common/src/assets/image/2020/template_list_week_with_tasks.png';
import template_list_day_split_timeslots from '../../../../common/src/assets/image/2020/template_list_day_split_timeslots.png';
import day_split_timeslots from '../../../../common/src/assets/image/2020/day_split_timeslots.png';
import page_layout_themes from '../../../../common/src/assets/image/2020/page_layout_themes.png';
import page_layout_colors from '../../../../common/src/assets/image/2020/page_layout_colors.png';
import mini_calendar_formula from '../../../../common/src/assets/image/2020/mini_calendar_formula.png';
import two_years_stacked from '../../../../common/src/assets/image/2020/2_years_stacked.png';
import three_years_side_by_side from '../../../../common/src/assets/image/2020/3_years_side_by_side.png';
import three_years_stacked from '../../../../common/src/assets/image/2020/3_years_stacked.png';
import four_years from '../../../../common/src/assets/image/2020/4_years.png';
import week_grid_with_task_list from '../../../../common/src/assets/image/2020/week_grid_with_task_list.png';
import school_year from '../../../../common/src/assets/image/2020/school_year.png';
import themeable_6_month from '../../../../common/src/assets/image/2020/themeable_6_month.png';
import themeable_12_months_with_list from '../../../../common/src/assets/image/2020/themeable_12_months_with_list.png';
import themeable_month from '../../../../common/src/assets/image/2020/themeable_month.png';
import week_columns_with_task_list from '../../../../common/src/assets/image/2020/week_columns_with_task_list.png';
import year_landscape from '../../../../common/src/assets/image/2020/year_landscape.png';
import year_landscape_with_list from '../../../../common/src/assets/image/2020/year_landscape_with_list.png';
import year_month_columns from '../../../../common/src/assets/image/2020/year_month_columns.png';
import year_month_rows from '../../../../common/src/assets/image/2020/year_month_rows.png';
import year_portrait from '../../../../common/src/assets/image/2020/year_portrait.png';
import year_portrait_with_list from '../../../../common/src/assets/image/2020/year_portrait_with_list.png';
import year_portrait_with_week_numbers from '../../../../common/src/assets/image/2020/year_portrait_with_week_numbers.png';
import outlook_2003 from '../../../../common/src/assets/image/2020/outlook_2003.png';

const offset = 120;

const toc = (
	<div>
		<p>
			PrintableCal 2020 includes 34 new templates (for a total of 96 templates!) and many other improvements. This is a <strong>free update</strong> for anyone that purchased PrintableCal 2019 and has an active subscription. If you purchased a PrintableCal 1.x license, you qualify for a 50%
			discount on the upgrade. Please <Link to="/#contact_us_section">contact us</Link> to receive your discount.
		</p>
		<ul>
			<li><AnchorLink href="#year-new-layouts" offset={offset}>Create year calendars using new layouts</AnchorLink></li>
			<li><AnchorLink href="#day-new-layout" offset={offset}>Create day calendars using a new layout</AnchorLink></li>
			<li><AnchorLink href="#multiple-years" offset={offset}>Output multiple years to a single page</AnchorLink></li>
			<li><AnchorLink href="#week-task-list" offset={offset}>Include task list on week template</AnchorLink></li>
			<li><AnchorLink href="#twelve-month-list" offset={offset}>Include item list on 12 month template</AnchorLink></li>
			<li><AnchorLink href="#month-outlook-2003" offset={offset}>Print Outlook 2003 monthly style template</AnchorLink></li>
			<li><AnchorLink href="#themeable-calendars" offset={offset}>Change color theme of generated calendars</AnchorLink></li>
			<li><AnchorLink href="#multiple-mini-calendars" offset={offset}>Include multiple mini-calendars</AnchorLink></li>
			<li><AnchorLink href="#week-numbers" offset={offset}>See week numbers on year calendar</AnchorLink></li>
			<li><AnchorLink href="#many-other-improvements" offset={offset}>Many other improvements and corrections</AnchorLink></li>
		</ul>
	</div>
);

const yearNewLayouts = (
	<div id="year-new-layouts">
		<h2>Create year calendars using new layouts</h2>
		<p>
			PrintableCal 2020 adds template layouts for showing a whole year with months as rows or columns. These new templates are under the <strong>Year - Months as Columns</strong> and <strong>Year - Months as Rows</strong> groups in the template list.
			Additional templates have also been added under the <strong>Year</strong> group, for different page orientations with and without event lists.
		</p>
		<h3>Default Year (Months as Columns) Template</h3>
		<p>This template consists of 12 months, where each month is defined as a column and the days of the month are defined in rows. This layout can be used to generate an entire year to a single page. Dates that contain events will be color-coded for up to 5 events.<br/>
			<Image lazy={false} alt="" src={year_month_columns} style={{width: 914}} />
		</p>
		<h3>Default Year (Months as Rows) Template</h3>
		<p>This template consists of 12 months, where each month is defined as a row and the days of the month are defined in columns. This layout can be used to generate an entire year to a single page. Dates that contain events will be color-coded to match the first event.<br/>
			<Image lazy={false} alt="" src={year_month_rows} style={{width: 760}} />
		</p>
		<h3>Year Template - Landscape Orientation</h3>
		<p>This template generates a calendar showing an entire year on a single page in landscape orientation. Each day can be color-coded based on the first event or task occurring on that date.<br/>
			<Image lazy={false} alt="" src={year_landscape} style={{width: 627}} />
		</p>
		<h3>Year Template - Landscape Orientation with List</h3>
		<p>This template generates  a calendar showing an entire year on a single page in landscape orientation. Each day can be color-coded based on the first event or task occurring on that date. A list of events and tasks will be shown on the right side of the page.<br/>
			<Image lazy={false} alt="" src={year_landscape_with_list} style={{width: 851}} />
		</p>
		<h3>Year Template - Portrait Orientation</h3>
		<p>Generate a calendar showing an entire year on a single page in portrait orientation. Each day can be color-coded based on the first event or task occurring on that date.<br/>
			<Image lazy={false} alt="" src={year_portrait} style={{width: 487}} />
		</p>
		<h3>Year Template - Portrait Orientation with List</h3>
		<p>This template generates a calendar showing an entire year on a single page in portrait orientation. Each day can be color-coded based on the first event or task occurring on that date. A list of events and tasks will be shown on the bottom half of the page.<br/>
			<Image lazy={false} alt="" src={year_portrait_with_list} style={{width: 759}} />
		</p>
	</div>
);

const dayNewLayouts = (
	<div id="day-new-layout">
		<h2>Create day calendars using a new layout</h2>
		<p>
			PrintableCal 2020 adds a day layout where time slots are split between the left and right sides of the page.
			The new template can be found under the <strong>Day - 1 Day/Page with Split Timeslots</strong> group in the template list.
			<Image lazy={false} alt="" src={template_list_day_split_timeslots} style={{width: 468}} />
		</p>
		<p>
			Compared to typical day calendar layouts that show events in time slots, this new layout makes better use of the available space on a page, especially for calendars that don't contain many overlapping events.
			<Image lazy={false} alt="" src={day_split_timeslots} style={{width: 753}} />
		</p>
	</div>
);

const multipleYears = (
	<div id="multiple-years">
		<h2>Output multiple years to a single page</h2>
		<p>
			PrintableCal 2020 includes a variety of new templates for outputting multiple years (2, 3, or 4) on a single page. These templates can be found in the template list under the <strong>Year - 2 Years/Page</strong>, <strong>Year - 3 Years/Page
		</strong>, and <strong>Year - 4 Years/Page</strong> groups. The templates all use the default Office color theme and can be <AnchorLink href="#themable-calendars">changed to a different color theme</AnchorLink>.</p>
		<p><Image lazy={false} alt="" src={template_list_multiple_years} style={{width: 448}} /></p>
		<h3>2 Years Side-by-Side Template</h3>
		<p>This template generates 2 years to a page, displayed side-by-side.<br/>
			<Image lazy={false} alt="" src={two_years_side_by_side} style={{width: 584}} />
		</p>
		<h3>2 Years Stacked Template</h3>
		<p>This template generates 2 years to a page, stacked vertically.<br/>
			<Image lazy={false} alt="" src={two_years_stacked} style={{width: 877}} />
		</p>
		<h3>3 Years Side-by-Side Template</h3>
		<p>This template generates 3 years to a page, displayed side-by-side.<br/>
			<Image lazy={false} alt="" src={three_years_side_by_side} style={{width: 1006}} />
		</p>
		<h3>3 Years Stacked Template</h3>
		<p>This template generates 3 years to a page, stacked vertically.<br/>
			<Image lazy={false} alt="" src={three_years_stacked} style={{width: 720}} />
		</p>
		<h3>Default 4 Years Template</h3>
		<p>This template generates 4 years to a page, in a 2x2 grid.<br/>
			<Image lazy={false} alt="" src={four_years} style={{width: 835}} />
		</p>
	</div>
);

const weekTaskList = (
	<div id="week-task-list">
		<h2>Include task list on week template</h2>
		<p>
			PrintableCal 2020 adds week grid and column templates that include a task list. These templates can be found in the template list under the <strong>Week - Columns with Task List</strong> and <strong>Week - Grid with Task List
			</strong> groups.</p>
		<p><Image lazy={false} alt="" src={template_list_week_with_tasks} style={{width: 445}} /></p>
		<h3>Default Week Column with Task List Template</h3>
		<p>This template generates a calendar showing 7 days on a single page. Each day will be a column, with all-day events at the top and timeslot rows below. Events that have start/end times will be placed within their associated timeslot cells. Tasks that are overdue or due within the displayed week will be listed to the right of the day columns.<br/>
			<Image lazy={false} alt="" src={week_columns_with_task_list} style={{width: 1059}} />
		</p>
		<h3>Default Week Grid with Task List Template</h3>
		<p>This template generates a calendar showing 7 days on a single page, arranged in a 2x3 grid layout. Each event will be ordered based on its starting time. All-day events will be placed at the top of each day cell. Tasks that are overdue or due within the displayed week will be listed to the right of the day grid.<br/>
			<Image lazy={false} alt="" src={week_grid_with_task_list} style={{width: 782}} />
		</p>
	</div>
);

const twelveMonthList = (
	<div id="twelve-month-list">
		<h2>Include item list on 12 month template</h2>
		<p>
			PrintableCal 2020 adds 12-month templates that include a listing of events and tasks, starting at any month. These new templates are under the <strong>Month - 12 Months/Page</strong> group in the template list. They use the default Office color theme and can
			be <AnchorLink href="#themable-calendars">changed to a different color theme</AnchorLink>.</p>
		<h3>School Year Calendar</h3>
		<p>This template shows 6 months on the left and right sides of the page, and a listing of events in the middle. Space is also provided for your school or business name and contact information.<br/>
			<Image lazy={false} alt="" src={school_year} style={{width: 794}} />
		</p>
		<h3>Themeable 12-Month Template with List</h3>
		<p>This template generates 12 months to a single page, using the Office color theme. A listing of events and tasks will be shown to the right of the months. Different color themes can be selected on the Page Layout tab in Excel.<br/>
			<Image lazy={false} alt="" src={themeable_12_months_with_list} style={{width: 805}} />
		</p>
	</div>
);

const monthOutlook2003 = (
	<div id="month-outlook-2003">
		<h2>Print Outlook 2003 monthly style template</h2>
		<p>
			PrintableCal 2020 adds a month template that matches the retro look-and-feel of the Outlook 2003 monthly print style. If you have a later version of Outlook and miss that older monthly print style, it's now provided in PrintableCal. The new
			template can be found under the <strong>Month</strong> group in the template list. The template is named <strong>Month Template - Outlook 2003</strong>.</p>
		<p>
			<Image lazy={false} alt="" src={outlook_2003} style={{width: 1288}} />
		</p>
	</div>
);

const themeableCalendars = (
	<div id="themeable-calendars">
		<h2>Change color theme of generated calendars</h2>
		<p>
			PrintableCal 2020 adds themeable versions of many of the calendar templates. These templates can be easily changed to other color themes via the Page Layout tab in Excel.</p>
		<p>Here's an example of the Themeable 6-Month Template, using the default <strong>Office</strong> theme:<br/>
			<Image lazy={false} alt="" src={themeable_6_month} style={{width: 945}} />
		</p>
		<h3>How to change the theme</h3>
		<p>To select a different theme, click the <strong>Themes</strong> button. Themes include colors and fonts. Here's how the template looks when using the <strong>Damask</strong> theme:<br/>
			<Image lazy={false} alt="" src={page_layout_themes} style={{width: 971}} />
		</p>
		<h3>How to change the color palette</h3>
		<p>To keep the fonts but just change the colors, click the <strong>Colors</strong> button and select the desired palette. Here's how the template looks when using the <strong>Violet II</strong> color palette:<br/>
			<Image lazy={false} alt="" src={page_layout_colors} style={{width: 971}} />
		</p>
		<h3>How to save a different theme or color palette as the default</h3>
		<p>If you prefer a certain theme or color, you can customize the template to use that theme or color as the default. Click the <strong>Customize</strong> button on the PrintableCal tab of the ribbon in Excel. Select the template you'd like to
			modify. Next, pick the desired theme or color on the <strong>Page Layout</strong> tab of the ribbon. Save the modified template by clicking the <strong>Save</strong> button on the <strong>PrintableCal - Save as Template</strong> panel. The
			next time you generate a calendar using the selected template, it will use the updated theme or color instead of the default. You can also perform more advanced customizations. See the <Link to="/Documentation/How-To/Customize-a-Template">
				Customize a Template</Link> walkthrough to learn more.</p>
	</div>
);

const multipleMiniCalendars = (
	<div id="multiple-mini-calendars">
		<h2>Include multiple mini-calendars</h2>
		<p>
			PrintableCal 2020 adds support for insertion of 2 mini-calendars in the templates. Previously, only a single mini-calendar could be inserted. The mini-calendar can contain multiple contiguous months, but this doesn't always make sense for certain
			templates. For example, it makes more sense for the Month template to show mini-calendars for the previous and next months, but not the current month.</p>
		<p>Several templates have been created or updated to take advantage of this new feature, such as the <strong>Themeable Month Template - Landscape</strong>. See below for an example of how it looks. Notice the previous and next months are shown in the
			bottom-right corner of the template.<br/>
			<Image lazy={false} alt="" src={themeable_month} style={{width: 1434}} />
		</p>
		<p>If you'd like to <Link to="/Documentation/How-To/Customize-a-Template">
			customize a template</Link> to include multiple mini calendars in the output, name the cell ranges where the calendars should appear as <strong>MiniCalendar1</strong> and <strong>MiniCalendar2</strong>, and define the cell contents using the
			MiniCalendar() formula as <Link to="/Documentation/Insert-Mini-Calendar">described in the documentation</Link>. In the example below, two mini calendars will be inserted, each showing a single month. The first will show the previous month, and
			the second will show the next month, by using the <strong>StartMonthOffset</strong> parameter.<br/>
			<Image lazy={false} alt="" src={mini_calendar_formula} style={{width: 1246}} />
		</p>
	</div>
);

const seeWeekNumbers = (
	<div id="week-numbers">
		<h2>See week numbers on year calendar</h2>
		<p>
			PrintableCal 2020 adds support for showing week numbers in year templates. A new template named <strong>Year Template - Portrait Orientation with Week Numbers</strong> has been added to take advantage of this new feature. Week numbers
			will appear to the left of each month. The numbers will be calculated using ISO 8601 if the first day of the week is set to Monday. Otherwise, simple week numbering (as used in the United States and Canada) will be used.</p>
		<p>Here's an example of the new template showing week numbers:<br/>
			<Image lazy={false} alt="" src={year_portrait_with_week_numbers} style={{width: 697}} />
		</p>
		<p>If you'd like to <Link to="/Documentation/How-To/Customize-a-Template">
			customize a year template</Link> to include week numbers in the output, name a cell where the first week number should appear as <strong>WeekNumber</strong>. Week numbers for following weeks and months will be placed relative to the position
			of the first week number cell.
		</p>
	</div>
);

const manyOtherCorrectionsAndImprovements = (
	<div id="many-other-improvements" style={{marginBottom: 150}}>
		<h2>Many other improvements and corrections</h2>
		<p>Besides all the improvements noted above, there's a lot more...</p>
		<ul>
			<li>Improved Excel, Word, and PowerPoint startup speed by only loading PrintableCal on demand instead of on startup.</li>
			<li>Improved template selection to show an example preview when hovering the mouse over a template's name.</li>
			<li>Improved the user interface for entering numeric values (such as the year and number of rows) in template settings.</li>
			<li>Corrected templates to properly fit the page size.</li>
			<li>Corrected cases where additional unnecessary white space could be shown when item text is wrapped.</li>
			<li>Corrected positioning problems of overlapping events in templates that include timeslot columns.</li>
			<li>Corrected alignment of calendar legends and mini calendars to match the text alignment of the target cell.</li>
			<li>Corrected an intermittent message during calendar generation asking to confirm deletion of temporary worksheets.</li>
			<li>Many other corrections and improvements.</li>
		</ul>
	</div>
);

const content = (
	<div className="sectioned">
		{toc}
		{yearNewLayouts}
		{dayNewLayouts}
		{multipleYears}
		{weekTaskList}
		{twelveMonthList}
		{monthOutlook2003}
		{themeableCalendars}
		{multipleMiniCalendars}
		{seeWeekNumbers}
		{manyOtherCorrectionsAndImprovements}
	</div>
);

const Documentation = () => {
	return (
		<DocumentationSection
			url="https://www.printablecal.com/Whats-New-Version-2020"
			commentsId="whats_new_version_2020"
			title="What's New in PrintableCal 2020"
			description="PrintableCal 2020 includes 34 new calendar templates and many other improvements."
			keywords="PrintableCal, release notes, version history"
			content={content}
		/>
	);
};

export default Documentation;
